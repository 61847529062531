[CHAR_INT, CMD_INT, RESP_INT, START_INT, RESTART_INT] = [75, 100, 2000, 2500, 30000]
SCRN_COMMANDS = ["ksplice -y all upgrade", "ksplice all show"];
SCRN_RESULTS = [
    [
        "The following steps will be taken:",
        "Install [8v0l3voi]: CVE-2016-0702: RSA key disclosure on Sandy Bridge CPU's (CacheBleed).",
        "Install [qn7jy7c6]: CVE-2015-7547: Remote code execution in glibc DNS resolver.",
        "Done!",
        "",
        "The following steps will be taken:",
        "Install [b4ppb8m2] Race condition with outstanding tx counter in IP-over-InfiniBand.",
        "Install [280qdpcz] CVE-2016-3157: Xen I/O port access privilege escalation in x86-64.",
        "Installing [b4ppb8m2] Race condition with outstanding tx counter in IP-over-InfiniBand.",
        "Installing [280qdpcz] CVE-2016-3157: Xen I/O port access privilege escalation in x86-64.",
        "Your kernel is fully up to date.",
        "Effective kernel version is 4.1.12-32.2.3.el6uek",
    ],
    [
        "Ksplice user-space updates installed:",
        "",
        "rsyslogd (2318)",
        "",
        "sshd (2697):",
        " - [qn7jy7c6]: CVE-2015-7547: Remote code execution in glibc DNS resolver.",
        "",
        "certmonger (3007):",
        " - [qn7jy7c6]: CVE-2015-7547: Remote code execution in glibc DNS resolver.",
        " - [8v0l3voi]: CVE-2016-0702: RSA key disclosure on Sandy Bridge CPU's (CacheBleed).",
        "",
        "Ksplice kernel updates installed:",
        "",
        "Installed updates:",
        "[b4ppb8m2] Race condition with outstanding tx counter in IP-over-InfiniBand.",
        "[280qdpcz] CVE-2016-3157: Xen I/O port access privilege escalation in x86-64.",
        "",
        "Effective kernel version is 4.1.12-32.2.3.el6uek",
    ],
]

scroll = ->
    $(".screen").scrollTop(5000)

add_prompt = (id) ->
    $(".screen").append($("<p id='" + id + "'>(root) # </p>"))
    scroll()

write_response = (cmd_idx, resp_idx=0) ->
    responses = SCRN_RESULTS[cmd_idx]
    if resp_idx >= responses.length
        next_cmd = cmd_idx + 1
        add_prompt(next_cmd)
        if next_cmd >= SCRN_COMMANDS.length
            run_terminal()
        else
            setTimeout ->
              write_command next_cmd, 0
            , RESP_INT
    else
        line = $("<p class='response'>" + responses[resp_idx] + "</p>")
        $(".screen").append(line)
        scroll()
        setTimeout ->
          write_response cmd_idx, resp_idx+1
        , CMD_INT

write_command = (cmd_idx, cmd_char_idx) ->
    line = $(".screen p#" + cmd_idx)
    cur_cmd = SCRN_COMMANDS[cmd_idx]
    next_char = cur_cmd[cmd_char_idx]
    content = line.text()
    line.text(content + next_char)
    cmd_char_idx++
    if cmd_char_idx >= cur_cmd.length
        setTimeout ->
          write_response(cmd_idx)
        , RESP_INT
    else
        setTimeout ->
          write_command(cmd_idx, cmd_char_idx)
        , CHAR_INT

clear_terminal = ->
    $(".screen").empty()
    add_prompt(0)

run_terminal = ->
    setTimeout ->
        clear_terminal()
        write_command 0, 0
    , RESTART_INT

$(() ->
    setTimeout ->
        clear_terminal()
        write_command 0, 0
    , START_INT

    $('#explore').affix({
      offset:
        top: ->
          this.top = $('header').outerHeight(true) - 3
        bottom: ->
          this.bottom = $('footer').outerHeight(true) + 20
    })
)
